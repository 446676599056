import React from 'react'
import WalletConnect from './WalletConnect'
import bannerImage from '../image/tuYuanYuan3.png'
const Header = ({ defaultAccountChange }) => {
  const handleAccountChange = (value) => {
    defaultAccountChange(value)
  }
  return (
    <div className='headerContent'>
      <WalletConnect
        defaultAccountChange={handleAccountChange} />
      <div>
        <img src={bannerImage} alt="Banner" className="Banner"/>
      </div>
    </div>
  )
}

export default Header
