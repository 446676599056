import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

const IDOClaim = ({contract, defaultAccount, signer}) => {
    const defaultLink = "尚未連接錢包";
    const [refLink, setRefLink] = useState(defaultLink)
    const [copied, setCopied] = useState(false);

    if (defaultAccount === null) return;
    let link = "localhost:3000/inviter=";

    const alertCopied = () => {
        if (refLink === defaultLink)
            alert(`${defaultLink}`)
        else
            alert(`Your Reflink ${refLink} has been copied`);
    }

    const generateLink = () => {
        if (refLink !== defaultLink) return;
        let tempLink = link.concat(`${defaultAccount}`)
        console.log(tempLink)
        setRefLink(tempLink)
    }

    const claimToken = async () => {
        alert("Cannot claim right now")
    }

    generateLink();
    return (
        <div className='claimPage'>
            <div className='buttonClaim'>
                <button onClick={claimToken} id="btnClaim"> 领币 </button>
            </div>
            <div className='copyLink'>
                <CopyToClipboard text={refLink} onCopy={() => setCopied(true)}>
                    <button id="inviteLink" onClick={alertCopied}>
                        复制链结
                    </button>
                </CopyToClipboard>
            </div>
        </div>
    )
}

export default IDOClaim
