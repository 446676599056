import React from 'react'
import pngMechanism from '../image/Mechanism.png'
const IDOMechanism = () => {
    const style = {
        width: "100vw"
    }
    return (
        <div>
            <img src={pngMechanism} alt="Mechanism" style={style}/>
        </div>
    )
}

export default IDOMechanism
