import React, { useState } from 'react'
import IDOImage from '../image/TuYuanYuanIDO2.png'
import IDOModal from './IDOModal'
const IDO = (props) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const style = {
        position: 'absolute',
        // backgroundColor: 'transparent',
        color: '#efc75e',
        width: '40vw',
        height: '5vh',
        left: '30vw',
        top: '45vh',
        zIndex: '5'
    }

    const imageStyle = {
        width: '100vw'
    }

    const handleModalClose = () => {
        setModalIsOpen(false)
    }

    return (
        <div className='IDOPage'>
            <img src={IDOImage} alt="IDOImage" style={imageStyle} onClick={()=>setModalIsOpen(true)}/>
            <div className='IDOModal'>
                <IDOModal
                    contract={props.contract}
                    open={modalIsOpen}
                    onClose={handleModalClose}
                    signer={props.signer}
                    defaultAccount={props.defaultAccount}
                />
            </div>
        </div>
    )
}

export default IDO
