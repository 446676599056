import React, { useState } from 'react';
import { ethers } from 'ethers'
import tokenABI from '../abi/tokenabi.json'

const USDTAddress = "0x74e66780CE5924DF1Ff0A4913b7a767F8B12f7A6"
const defaultIDOAddress = "0x0D971B7B7520f1FCE9b90665CA59952ea2c52b04";

const IDOModal = ({ contract, open, onClose, signer, defaultAccount }) => {
    const [fatherAddress, setFatherAddress] = useState(null)

    if (defaultAccount === null) return;
    if (contract === null) return;
    if (signer === null) return;
    if (!open) return null;

    const setFather = () => {
        if (fatherAddress !== null) return;
        if (window.location.pathname.includes("inviter=")) {
            let tempAddress = window.location.pathname.slice(9, 51)
            setFatherAddress(tempAddress)
        }
        else {
            console.log("No inviter")
        }
    }

    setFather()

    const tokenContract = new ethers.Contract(USDTAddress, tokenABI, signer);
    const style = {
        maxWidth: '100px',
        marginTop: '2vh',
        height: '5vh',
        marginLeft: '60px',
        flex: '2'
    }
    const style2 = {
        flex: '1',
        marginTop: '2vh',
        marginLeft: '30px',
    }

    const makeApprove = async () => {
        console.log("Making approve...")
        const USDTamount = document.getElementById("amount").value;
        let realAmount = ethers.utils.parseEther(USDTamount);

        let result = await tokenContract.approve(contract.address, realAmount);
        if (result)
            await checkAllowanceAgain()
    }

    const checkAllowanceAgain = async () => {
        let result = await checkAllowance()

        if (Number(result) === 0)
            setTimeout(async () => {
                await checkAllowanceAgain()
                return;
            }, 3000)

        if (Number(result) > 0)
            makeIDO();
    }

    const checkAllowance = async () => {
        console.log("Checking allowance...")
        let tempAllowance = await tokenContract.allowance(defaultAccount, contract.address)
        return tempAllowance;
    }

    const makeIDO = async () => {
        let tempAllowance = await checkAllowance()

        const USDTamount = document.getElementById("amount").value;
        let realAmount = ethers.utils.parseEther(USDTamount);

        if (tempAllowance < realAmount) {
            await makeApprove()
        }
        if (tempAllowance >= realAmount) {
            console.log("Making IDO...")
            let result = await contract.makeIDO(defaultIDOAddress, realAmount)
            console.log(result)
        }
    }

    return (
        <div onClick={onClose} className='overlay'>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className="modalGameContainer"
            >
                <div className='modalRight'>
                    <p className='closeBtn' onClick={() => onClose(null)}>
                        X
                    </p>
                    <h4 align="middle">设定参与金额</h4><br />
                    <div className='ModalContent'>
                        <div className='ref'>
                            <span>推荐人：{fatherAddress.slice(0,4)}...{fatherAddress.slice(-4)}</span>
                        </div>
                        <div className='inputContainer'>
                            <input
                                autoComplete="off"
                                type="number"
                                id="amount"
                                name="amount"
                                placeholder="10 - 888"
                                style={style}
                            />
                            <div style={style2}>
                                USDT
                            </div>
                        </div>
                        <div className='IDOButtonDiv'>
                            <button id="buttonIDO" onClick={makeIDO}> Make IDO</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IDOModal;