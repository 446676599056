import React, { useState } from 'react'

const IDOStatus = ({ contract, defaultAccount }) => {
    const [hasJoined, setHasJoined] = useState("未加入")
    const [joinUSDT, setJoinUSDT] = useState(0)
    const [rewardUSDT, setRewardUSDT] = useState(0)
    const [referral, setReferral] = useState(0)

    const getStatus = async () => {
        if (hasJoined === "已加入") return;
        let tempJoin = await contract.isAddressJoined(defaultAccount)
        if (tempJoin === true)
            setHasJoined("已加入")
    }
    const getJoinUSDT = async () => {
        if (joinUSDT !== 0) return;
        let tempUSDT = await contract.AddressToJoinUsdtAmount(defaultAccount)
        setJoinUSDT(Number(tempUSDT / Math.pow(10, 18)))
    }
    const getReward = async () => {
        if (rewardUSDT !== 0) return;
        let tempRewardUSDT = await contract.AddressToRewardAmount(defaultAccount)
        setRewardUSDT(Number(tempRewardUSDT / Math.pow(10, 18)))
    }
    const getReferral = async () => {
        if (referral !== 0) return;
        let tempRef = await contract.AddressToReferralTimes(defaultAccount)
        setReferral(Number(tempRef));
    }

    const getData = async () => {
        if (defaultAccount === null) return;
        if (contract === null) return;
        getStatus()
        getJoinUSDT();
        getReward();
        getReferral()
    }

    getData()

    return (
        <div className='statusPage'>
            <div className='statusRow'>
                <div className='statusLeft'> 身分组：</div>
                <div className='statusRight'> {hasJoined}</div>
            </div>
            <div className='statusRow'>
                <div className='statusLeft'> IDO 数量：</div>
                <div className='statusRight'> {joinUSDT}</div>
            </div>
            <div className='statusRow'>
                <div className='statusLeft'>推广奖励：</div>
                <div className='statusRight'>{rewardUSDT}</div>
            </div>
            <div className='statusRow'>
                <div className='statusLeft'>推广人数：</div>
                <div className='statusRight'>{referral}</div>
            </div>
        </div>
    )
}

export default IDOStatus
