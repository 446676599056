import React, { useState } from 'react'
import money from '../image/money.png'
import BodyNavbar from './BodyNavbar';
import IDO from './IDO';
import IDOClaim from './IDOClaim';
import IDOTotal from './IDOTotal';
import IDOStatus from './IDOStatus';
import IDOMechanism from './IDOMechanism';

const Body = ({ defaultAccount, contract, signer }) => {
  const page1 = document.getElementById("Page1")
  const page2 = document.getElementById("Page2")
  const page3 = document.getElementById("Page3")
  const page4 = document.getElementById("Page4")

  const handlePageSelect = (value) => {
    console.log("The value is " + value)
    if (value === 0) {
      page1.hidden = false
      page2.hidden = true
      page3.hidden = true
      page4.hidden = true
    }
    if (value === 1) {
      page1.hidden = true
      page2.hidden = false
      page3.hidden = true
      page4.hidden = true
    }
    if (value === 2) {
      page1.hidden = true
      page2.hidden = true
      page3.hidden = false
      page4.hidden = true
    }
    if (value === 3) {
      page1.hidden = true
      page2.hidden = true
      page3.hidden = true
      page4.hidden = false
    }
  }

  return (
    <div className='bodyContent'>

      <div className='bodyInviteArea'>
        <div className='bodyInviteAreaLeft'>
          <span id="bodyContentTitle">即邀即得 5% 反佣 <br /></span>
          <span id="bodyContentContent">邀好友 立马得 USDT 奖励</span>
        </div>
        <div className='bodyInviteAreaRight'>
          <img src={money} alt="money" id="moneyPic" />
        </div>
      </div>

      <div className='bodyNavbarArea'>
        <BodyNavbar
          whichPage={handlePageSelect} />
      </div>

      <div className='bodyAccordingToNav'>
        <div id='Page1'>
          <IDO contract={contract} signer={signer} defaultAccount={defaultAccount} />
        </div>
        <div id='Page2' hidden>
          <IDOStatus contract={contract} defaultAccount={defaultAccount} />
        </div>
        <div id='Page3' hidden>
          <IDOMechanism />
        </div>
        <div id='Page4' hidden>
          <IDOClaim contract={contract} signer={signer} defaultAccount={defaultAccount} />
        </div>
      </div>
      <div className='whiteLine'>
        <span>总参与进度</span>
      </div>
      <IDOTotal contract={contract} />
    </div>
  )
}

export default Body