import React, { useState } from 'react';
import Header from './component/Header';
import Body from './component/Body';
import Footer from './component/Footer';
import { ethers } from 'ethers';
import './App.css';
import contractABI from './abi/contractabi.json'
const CONTRACT_ADDRESS = "0xA45268642CcC90361de5607a8B58a53C5Bf800d2"

function App() {
  const [provider, setProvider] = useState(null)
  const [signer, setSigner] = useState(null)
  const [defaultAccount, setDefaultAccount] = useState(null)
  const [contract, setContract] = useState(null)
  const [correctNetwork, setCorrectNetwork] = useState(false)

  const handleAccountChange = async (value) => {
    setDefaultAccount(value);
    await checkCorrectNetwork()
    await updateEthers();
  }

  const updateEthers = async () => {
    if (correctNetwork === false) return;
    let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(tempProvider);

    let tempSigner = tempProvider.getSigner();
    setSigner(tempSigner);

    let tempContract = new ethers.Contract(CONTRACT_ADDRESS, contractABI, tempSigner)
    setContract(tempContract)
  }

  const checkCorrectNetwork = async () => {
    const { ethereum } = window
    let chainId = await ethereum.request({ method: 'eth_chainId' })

    const netWorkID = '0x42'

    if (chainId !== netWorkID) {
      setCorrectNetwork(false)
      console.log("Please Connect to the Correct Network")
    } else {
      setCorrectNetwork(true)
    }
  }

  return (
    <div className="App">
      <div className='header'>
        <Header
          defaultAccountChange={handleAccountChange}
        />
      </div>
      <div className='body'>
        <Body
          defaultAccount={defaultAccount}
          contract={contract}
          signer={signer}
        />
      </div>
      {/* <div className='footer'>
        <Footer />
      </div> */}
    </div>
  );
}

export default App;
