import React, { useState } from 'react'

const IDOTotal = ({ contract }) => {
  const [totalDistributor, setTotalDistributor] = useState(0);
  const [totalUSDT, setTotalUSDT] = useState(0);
  // const [totalReward, setTotalReward] = useState(0);

  const getData = async () => {
    if (totalDistributor !== 0) return;
    let tempDistributor = await contract.totalJoinTimes();
    setTotalDistributor(Number(tempDistributor))
  }
  const getData2 = async () => {
    if (totalUSDT !== 0) return;
    // if (totalReward !== 0) return;
    let tempUSDT = await contract.totalJoinUSDT();
    setTotalUSDT(Number(tempUSDT / Math.pow(10, 18)))
    // setTotalReward(Number(tempUSDT / Math.pow(10, 18)) * 0.05)
  }

  const gettingData = async () => {
    if (contract === null) return;
    getData();
    getData2();
  }

  gettingData();
  return (
    <div className='IDOTotalSection'>
      <div className='statusRow'>
        <div className='IDOTotalSectionLeft'> 总参与人数</div>
        <div className='IDOTotalSectionRight'> {totalDistributor}</div>
      </div>
      <div className='statusRow'>
        <div className='IDOTotalSectionLeft'> 总参与金额</div>
        <div className='IDOTotalSectionRight'> {totalUSDT} USDT</div>
      </div>
      {/* <div className='statusRow'>
        <div className='IDOTotalSectionLeft'> 总推荐奖金</div>
        <div className='IDOTotalSectionRight'> {totalReward} USDT</div>
      </div> */}
    </div>
  )
}

export default IDOTotal
