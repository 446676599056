import React, { useState } from 'react'

const BodyNavbar = ({whichPage}) => {
    const [underlined1, setUnderlined1] = useState(true);
    const [underlined2, setUnderlined2] = useState(false);
    const [underlined3, setUnderlined3] = useState(false);
    const [underlined4, setUnderlined4] = useState(false);

    const textStyle1 = {
        textDecoration: underlined1 ? 'underline' : 'none',
        // color: underlined1 ? '#efc75e' : 'white',
        color: underlined1 ? 'red' : 'black',
        border: 'none',
        background: 'transparent',
        fontWeight: 'bold',
        cursor : 'pointer'
    };

    const textStyle2 = {
        textDecoration: underlined2 ? 'underline' : 'none',
        // color: underlined2 ? '#efc75e' : 'white',
        color: underlined2 ? 'red' : 'black',
        border: 'none',
        background: 'transparent',
        fontWeight: 'bold',
        cursor : 'pointer'
    };

    const textStyle3 = {
        textDecoration: underlined3 ? 'underline' : 'none',
        // color: underlined3 ? '#efc75e' : 'white',
        color: underlined3 ? 'red' : 'black',
        border: 'none',
        background: 'transparent',
        fontWeight: 'bold',
        cursor : 'pointer'
    };

    const textStyle4 = {
        textDecoration: underlined4 ? 'underline' : 'none',
        // color: underlined4 ? '#efc75e' : 'white',
        color: underlined4 ? 'red' : 'black',
        border: 'none',
        background: 'transparent',
        fontWeight: 'bold',
        cursor : 'pointer'
    };

    const handleClick = (value) => {
        whichPage(value);
        if (value === 0) {
            if (underlined1 === true) return;
            console.log("參加IDO")
            setUnderlined1(true)
            setUnderlined2(false)
            setUnderlined3(false)
            setUnderlined4(false)
        }
        if (value === 1) {
            if (underlined2 === true) return;
            console.log("個人面板")
            setUnderlined1(false)
            setUnderlined2(true)
            setUnderlined3(false)
            setUnderlined4(false)
        }
        if (value === 2) {
            if (underlined3 === true) return;
            console.log("代幣機制")
            setUnderlined1(false)
            setUnderlined2(false)
            setUnderlined3(true)
            setUnderlined4(false)
        }
        if (value === 3) {
            if (underlined4 === true) return;
            console.log("想想放啥")
            setUnderlined1(false)
            setUnderlined2(false)
            setUnderlined3(false)
            setUnderlined4(true)
        }
    }
    return (
        <div className='bodyNav'>
            <div className='bodyNavElement'>
                <button onClick={() => handleClick(0)} id="bodyNavSpan1" style={textStyle1}>参加IDO</button>
            </div>
            <div className='bodyNavElement'>
                <button onClick={() => handleClick(1)} id="bodyNavSpan2" style={textStyle2}>个人面板</button>
            </div>
            <div className='bodyNavElement'>
                <button onClick={() => handleClick(2)} id="bodyNavSpan3" style={textStyle3}>代币机制</button>
            </div>
            <div className='bodyNavElement'>
                <button onClick={() => handleClick(3)} id="bodyNavSpan4" style={textStyle4}>邀请/领币</button>
            </div>
        </div>
    )
}

export default BodyNavbar
